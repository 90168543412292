
















































import FedraLink from '@client/components/Helpers/FedraLink.vue'
import Locale from '@microservice/Common/Search/Type/LocaleType'
import { Component, getModule, Vue } from 'nuxt-property-decorator'
import FedraInstanceMetaModule from '@client/store/Module/FedraInstanceMetaModule'
import FedraInstanceInfo from '@client/store/Domain/Support/FedraInstance/FedraInstanceInfo'

@Component({
	name: 'LegacyLanguageSelector',
	components: { FedraLink }
})
export default class LegacyLanguageSelector extends Vue {

	fedraInstanceMetaMD: FedraInstanceMetaModule

	get getRegions() {
		return this.fedraInstanceMetaMD.getActiveRegions
	}

	get getInstancesByRegion(): (regionKey: string) => Record<Locale, FedraInstanceInfo> {
		return (regionKey: string) => this.fedraInstanceMetaMD.getInstancesByRegion(regionKey)
	}

	created() {
		this.fedraInstanceMetaMD = getModule(FedraInstanceMetaModule, this.$store)
	}
}
