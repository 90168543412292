






































































import { getModule } from 'vuex-module-decorators'
import { Component, Vue } from 'nuxt-property-decorator'
import SocialModule from '@client/store/Module/SocialModule'
import FedraLink from '@client/components/Helpers/FedraLink.vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import SocialMeta from '@client/store/Domain/Support/Social/SocialMeta'
import GenericModal from '@client/components/Utilities/GenericModal.vue'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import AboutBurgerButton from '@client/components/About/AboutBurgerButton.vue'
import SocialMetaUsageLocation from '@client/store/Domain/Support/Social/SocialMetaUsageLocation'
import LegacyLanguageSelector from '@client/components/LegacyLanguageSelector/LegacyLanguageSelector.vue'

@Component({
	name: 'AboutHeader',
	components: {
		AboutBurgerButton,
		FedraLink,
		GenericModal,
		LazyLegacyLanguageSelector: LegacyLanguageSelector
	}
})
export default class AboutHeader extends Vue {

	socialMD: SocialModule

	viewContent: string = ''
	isCountrySelectorModalActive = false

	$refs!: {
		legacyLanguageSelectorModal: GenericModal
		burgerMenuContentMiddle: HTMLElement
		burgerMenuContentBottom: HTMLElement
	}

	get facebookIcon(): typeof faFacebook {
		return faFacebook
	}

	get instagramIcon(): typeof faInstagram {
		return faInstagram
	}

	get getExitModalIcon(): typeof faTimes {
		return faTimes
	}

	get getAboutHeaderSocialMetas(): Array<SocialMeta> {
		return this.socialMD.getFilteredSocialMetasByUsageLocation(SocialMetaUsageLocation.AboutHeader)
	}

	public scrollIntoViewById(elementId: string): void {
		const element = document.getElementById(elementId)
		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}

	public closeCountrySelectorModal() {
		this.$refs.legacyLanguageSelectorModal.closeModal()
	}

	public openCountrySelectorModal() {
		this.$refs.legacyLanguageSelectorModal.openModal()
	}

	public updateMenuItemHighlights(): void	{
		if (process.client) {
			window.addEventListener("scroll", () => {
				const aboutHome = document.getElementById("aboutHome")
				const aboutWeAreFedra = document.getElementById("aboutWeAreFedra")
				const aboutFedraWorld = document.getElementById("aboutFedraWorld")
				const aboutJoinUs = document.getElementById("aboutJoinUs")
				const headerOffset = 100

				if (window.scrollY <= (aboutHome.offsetTop + aboutHome.offsetHeight) - headerOffset) {
					this.viewContent = 'aboutHome'
				}
				else if (window.scrollY <= (aboutWeAreFedra.offsetTop + aboutWeAreFedra.offsetHeight) - headerOffset) {
					this.viewContent = 'aboutWeAreFedra'
				}
				else if (window.scrollY <= (aboutFedraWorld.offsetTop + aboutFedraWorld.offsetHeight) - headerOffset) {
					this.viewContent = 'aboutFedraWorld'
				}
				else if (window.scrollY <= (aboutJoinUs.offsetTop + aboutJoinUs.offsetHeight) - headerOffset) {
					this.viewContent = 'aboutJoinUs'
				}
			})
		}
	}
	created() {
		this.socialMD = getModule(SocialModule, this.$store)
		if (process.client) {
			this.scrollIntoViewById('aboutHome')
		}
		this.updateMenuItemHighlights()
	}
}

